<template>
  <div class="login">
    <div class="row-bg">
      <div style="display: flex;justify-content: center;">
        <img height="80" :src="logo" style="border-radius: 10px;" alt="" />
      </div>
      <h2 style="text-align: center;">{{ system }}</h2>
      <el-form style="margin-top: 30px" label-width="100px">
        <el-form-item label="手机号">
          <el-input v-model="phone" autocomplete="off" placeholder="手机号" />
          <div v-if="errors.phone" style="margin-left: 204px;" class="error">{{ errors.phone }}</div>
        </el-form-item>
        <el-form-item label="验证码">
          <div style="display: flex; justify-content: center; height: 30px;">
            <el-input v-model="imgCode" autocomplete="off" style="width: 156px; height: 30px; margin-right: 20px;" @keyup.enter="submitForm"  placeholder="图片验证码" />
            <div v-html="codeImg" @click="getImgCode"></div>
          </div>
          <div v-if="errors.imgCode" style="margin-left: 64px;" class="error">{{ errors.imgCode }}</div>
        </el-form-item>
        <el-form-item label="短信验证码">
          <div>
            <el-input v-model="code" autocomplete="off" style="width: 156px;margin-right: 20px;" @keyup.enter="submitForm" placeholder="短信验证码" />
            <el-button style="width: 120px;" type="primary" :disabled="codeTxt.disabled" @click="getSMSCode">{{ codeTxt.txt }}</el-button>
          </div>
          <div v-if="errors.code" style="margin-left: 65px;" class="error">{{ errors.code }}</div>
        </el-form-item>
        <el-form-item label="用户身份">
          <el-radio-group v-model="identity" @change="identityChange">
            <el-radio label="partner"  style="margin-left: 10px;">我是合作商</el-radio>
            <!-- <el-radio label="customer" >我是客户</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-row justify="space-between" align="middle">
          <div style="font-size: 14px;cursor: pointer; color: #3aa20f;" @click="register">未有账号? 注册</div>
          <el-button type="primary" @click="submitForm">登录</el-button>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import axios from '@/libs/axios'
import { ref, reactive, onMounted } from 'vue'
import conf from '@/conf'
import { useStore } from 'vuex'
import router from '@/router'

const system = conf.system
const logo = conf.logo
const store = useStore()
const phone = ref('')
const code = ref('')
const identity = ref('partner')
const imgCode = ref('')
const codeImg = ref('')
// let timer = null
const codeTxt = reactive({
  txt: '获取验证码',
  disabled: false,
  time: 120
})
const errors = reactive({
  phone: '',
  code: '',
  imgCode: ''
})

const validate = () => {
  let hasErrors = false
  if (!phone.value) {
    errors.phone = '请输入手机号'
    hasErrors = true
  } else {
    errors.phone = ''
  }
  if (!code.value) {
    errors.code = '验证码不能为空'
    hasErrors = true
  } else {
    errors.code = ''
  }
  if (!imgCode.value) {
    errors.code = '验证码不能为空'
    hasErrors = true
  } else {
    errors.code = ''
  }
  return !hasErrors
}

const submitForm = async () => {
  if (!validate()) return
  try {
    if (identity.value == 'customer') {
      const response = await axios.post('/users/login', {
        phone: phone.value,
        code: code.value
      })
      store.dispatch('LOGIN', { ...response, identity: identity.value })
      // 登录成功，跳转到首页
      window.location.href = '/Home/Index'
    } else {
      const response = await axios.post('/users/adminLogin', {
        phone: phone.value,
        code: code.value
      })
      store.dispatch('LOGIN', { ...response, identity: identity.value })
      // 登录成功，跳转到首页
      window.location.href = '/Home/Index'
    }
  } catch (error) {
    console.error(error)
    // alert('登录失败，请检查手机号与验证码')
  }
}

// 测试阶段
const identityChange = e => {

}

const register = () => {
  router.push({ name: 'Register' })
}

const getSMSCode = async () => {
  if (!phone.value) {
    errors.phone = '请输入手机号'
    return
  }
  if (!imgCode.value) {
    errors.imgCode = '请输入验证码'
    return
  }
  errors.phone = ''
  errors.imgCode = ''
  try {
    const res = await axios.post('/users/getSMSCodes', { phone: phone.value, imgCode: imgCode.value })
    codeTxt.disabled = true
    const countdownInterval = setInterval(() => {
    codeTxt.txt = `${codeTxt.time}S后可获取`
    codeTxt.time--
      if (codeTxt.time < 0) {
        codeTxt.txt = '获取验证码'
        clearInterval(countdownInterval)
        codeTxt.disabled = false
        codeTxt.time = 120
      }
    }, 1000)
  } catch (err) {
    console.log('err :>> ', err)
  }
}

// 获取图片验证码
const getImgCode = async () => {
  const response = await axios.get('/users/getImgCode')
  // codeImg.value = window.URL.createObjectURL(response)
  codeImg.value = response
}

onMounted(() => {
  getImgCode()
})
</script>

<style scoped>
.error {
  color: red;
  position: absolute;
}
.login {
  background: url('../../public/20230725120532.jpg') no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}
.row-bg {
  position: fixed;
  background-color: #fff;
  right: 150px;
  z-index: 1;
  bottom: 120px;
  height: 400px;
  width: 400px;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
}
</style>
